export const ClientRoleShow = {
  marketParticipant: 'Market participant',
  issuer: 'Token Generator',
  marketOperator: 'Market operator',
  broker: 'Broker',
  investor: 'Investor',
};

export const ClientTypeShow = {
  legalEntity: 'Legal entity',
  individual: 'Individual',
};

export const ClientTypeForm = {
  ISSUER: 'issuer',
  BROKER: 'broker',
  INVESTOR_LEGAL: 'investor-legal',
  INVESTOR_INDIVIDUAL: 'investor-individual',
  MARKET_OPERATOR: 'marketOperator',
};
export const TermTypes = {
  GENERAL_TERMS: 'GENERAL_TERMS',
  PRIVACY_TERMS: 'PRIVACY_TERMS',
  PERSONAL_DATA_TERMS: 'PERSONAL_DATA_TERMS',
  NON_RESIDENT_TERMS: 'NON_RESIDENT_TERMS',
  OLDER_18_TERMS: 'OLDER_18_TERMS',
  OWN_NAME_AND_INTEREST_TERMS: 'OWN_NAME_AND_INTEREST_TERMS',
  VNX_GOLD_BASED_FRT_TERMS: 'VNX_GOLD_BASED_FRT_TERMS',
  AGREE_EMAIL_PROMOUTIONS: 'AGREE_EMAIL_PROMOUTIONS',
};
export const KYCStatus = { NEW: 'new', COMPLETED: 'completed', PASSED: 'passed', REJECTED: 'rejected', RETRY: 'retry' };
export const AccountType = {
  OBLIGATIONS: 'Obligations',
};
export const ClientRoutes = {
  CLIENTS_ROOT: '/clients',
  [ClientTypeForm.INVESTOR_INDIVIDUAL]: '/clients/investor/individual',
  [ClientTypeForm.INVESTOR_LEGAL]: '/clients/investor/legal',
  [ClientTypeForm.ISSUER]: '/clients/issuer/legal',
  [ClientTypeForm.BROKER]: '/clients/broker/legal',
  [ClientTypeForm.MARKET_OPERATOR]: '/clients/operator/legal',
};
export const ClientTabsRoutes = {
  INFO: 'info',
  ACCOUNTS: 'accounts',
  OFFERINGS: 'offerings',
  USERS: 'users',
  DESTINATIONS: 'destinations',
  API_KEYS: 'keys',
  PROGRAMS: 'programs',
};
export const ClientsTabsRoutes = {
  ACTIVE: 'active',
  DELETED: 'deleted',
  REFERRAL: 'referral',
  NOTIFICATIONS: 'notifications',
};
export const clientsTabs = {
  active: { name: 'Active Clients', index: 0, route: ClientsTabsRoutes.ACTIVE },
  deleted: { name: 'Deleted Clients', index: 1, route: ClientsTabsRoutes.DELETED },
  referral: { name: 'Referral Program', index: 2, route: ClientsTabsRoutes.REFERRAL },
  notifications: { name: 'Notifications History', index: 3, route: ClientsTabsRoutes.NOTIFICATIONS },
};

export const clientTabs = {
  [ClientTypeForm.INVESTOR_INDIVIDUAL]: {
    info: { name: 'Individual Info', index: 0, route: ClientTabsRoutes.INFO },
    accounts: { name: 'Accounts', index: 1, route: ClientTabsRoutes.ACCOUNTS },
    offerings: { name: 'My Offerings', index: 2, route: ClientTabsRoutes.OFFERINGS },
    users: { name: 'Users', index: 3, route: ClientTabsRoutes.USERS },
    destinations: { name: 'Payment Destinations', index: 4, route: ClientTabsRoutes.DESTINATIONS },
    keys: { name: 'API Keys', index: 5, route: ClientTabsRoutes.API_KEYS },
    programs: { name: 'Discount Programs', index: 6, route: ClientTabsRoutes.PROGRAMS },
  },
  [ClientTypeForm.INVESTOR_LEGAL]: {
    info: { name: 'Individual Info', index: 0, route: ClientTabsRoutes.INFO },
    accounts: { name: 'Accounts', index: 1, route: ClientTabsRoutes.ACCOUNTS },
    offerings: { name: 'My Offerings', index: 2, route: ClientTabsRoutes.OFFERINGS },
    users: { name: 'Users', index: 3, route: ClientTabsRoutes.USERS },
    destinations: { name: 'Payment Destinations', index: 4, route: ClientTabsRoutes.DESTINATIONS },
    keys: { name: 'API Keys', index: 5, route: ClientTabsRoutes.API_KEYS },
    programs: { name: 'Discount Programs', index: 6, route: ClientTabsRoutes.PROGRAMS },
  },
  [ClientTypeForm.ISSUER]: {
    info: { name: 'Individual Info', index: 0, route: ClientTabsRoutes.INFO },
    accounts: { name: 'Accounts', index: 1, route: ClientTabsRoutes.ACCOUNTS },
    offerings: { name: 'My Offerings', index: 2, route: ClientTabsRoutes.OFFERINGS },
    users: { name: 'Users', index: 3, route: ClientTabsRoutes.USERS },
    destinations: { name: 'Payment Destinations', index: 4, route: ClientTabsRoutes.DESTINATIONS },
    keys: { name: 'API Keys', index: 5, route: ClientTabsRoutes.API_KEYS },
    programs: { name: 'Discount Programs', index: 6, route: ClientTabsRoutes.PROGRAMS },
  },
  [ClientTypeForm.BROKER]: {
    info: { name: 'Individual Info', index: 0, route: ClientTabsRoutes.INFO },
    accounts: { name: 'Accounts', index: 1, route: ClientTabsRoutes.ACCOUNTS },
    offerings: { name: 'My Offerings', index: 2, route: ClientTabsRoutes.OFFERINGS },
    users: { name: 'Users', index: 3, route: ClientTabsRoutes.USERS },
    destinations: { name: 'Payment Destinations', index: 4, route: ClientTabsRoutes.DESTINATIONS },
    keys: { name: 'API Keys', index: 5, route: ClientTabsRoutes.API_KEYS },
  },
  [ClientTypeForm.MARKET_OPERATOR]: {
    info: { name: 'Individual Info', index: 0, route: ClientTabsRoutes.INFO },
    accounts: { name: 'Accounts', index: 1, route: ClientTabsRoutes.ACCOUNTS },
    users: { name: 'Users', index: 2, route: ClientTabsRoutes.USERS },
  },
};

