import { initialFetching, createFetching } from 'symbiote-fetching';
import { createSymbiote } from 'redux-symbiote';

export const initialState = {
  fetchOne: initialFetching,
  fetchTransferTrades: initialFetching,
  fetchGetTerminateTrades: initialFetching,
  fetchCancelTerminateTrades: initialFetching,
  terminateTrades: [],
  terminateTradesFilter: {},
  trades: [],
  goldenTrades: [],
  goldenTradesTotal: 0,
  availSettlementCurrencies: [],
};

const symbiote = {
  fetchOne: createFetching('fetchOne'),
  fetchTransferTrades: createFetching('fetchTransferTrades'),
  fetchGetTerminateTrades: createFetching('fetchGetTerminateTrades'),
  fetchCancelTerminateTrades: createFetching('fetchCancelTerminateTrades'),
  setTrades: (state, data) => ({ ...state, trades: data }),
  setGoldenTrades: (state, data) => ({ ...state, goldenTrades: data }),
  setGoldenTradesTotal: (state, data) => ({ ...state, goldenTradesTotal: data }),
  setAvailSettlementCurrencies: (state, data) => ({ ...state, availSettlementCurrencies: data }),
  setTerminateTrades: (state, data) => ({ ...state, terminateTrades: data }),
  setTerminateTradesFilter: (state, data) => ({ ...state, terminateTradesFilter: data }),
};

export const { actions, reducer } = createSymbiote(initialState, symbiote, 'trades');
