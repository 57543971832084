import { initialFetching, createFetching } from 'symbiote-fetching';
import { createSymbiote } from 'redux-symbiote';

export const initialState = {
  fetchOne: initialFetching,
  fetchCancelGoldenOrder: initialFetching,
  fetchCompleteGoldenOrder: initialFetching,
  fetchReverseClientOrder: initialFetching,
  orders: [],
  ordersTotal: 0,
  ordersFilter: {},
  order: {},
  completeSellOrderCheck: {},
};

const symbiote = {
  fetchOne: createFetching('fetchOne'),
  fetchCancelGoldenOrder: createFetching('fetchCancelGoldenOrder'),
  fetchCompleteGoldenOrder: createFetching('fetchCompleteGoldenOrder'),
  fetchReverseClientOrder: createFetching('fetchReverseClientOrder'),
  setOrders: (state, data) => ({ ...state, orders: data }),
  setOrdersTotal: (state, data) => ({ ...state, ordersTotal: data }),
  setOrdersFilter: (state, data) => ({ ...state, ordersFilter: data }),
  setOrder: (state, data) => ({ ...state, order: data }),
  setCompleteSellOrderSell: (state, data) => ({ ...state, completeSellOrderCheck: data }),
};

export const { actions, reducer } = createSymbiote(initialState, symbiote, 'orders');
