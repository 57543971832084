import { handleFetching } from 'symbiote-fetching';

import { getResponse } from 'features/common';

import { actions } from './symbiotes';
import { reportsApi } from './api';

export const getReport = query =>
  handleFetching(actions.fetchReport, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(reportsApi.getClientsReport, query);
        const { ok, error, data } = getResponse(response);
        return { ok, error, data };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getClientsReport = query =>
  handleFetching(actions.fetchClientsReport, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(reportsApi.getClientsReport, query);
        const { ok, error, data } = getResponse(response);
        return { ok, error, data };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getBookbuildingReport = (id, query) =>
  handleFetching(actions.fetchOne, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(reportsApi.getBookbuildingReport, id, query);
        const { ok, error, data } = getResponse(response);
        return { ok, error, data };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getAccountStatementReport = query =>
  handleFetching(actions.fetchOne, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(reportsApi.getAccountStatementReport, query);
        const { ok, error, data } = getResponse(response);
        return { ok, error, data };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getHistoryStatuses = () =>
  handleFetching(actions.fetchOne, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(reportsApi.getHistoryStatuses);
        const { ok, error, data } = getResponse(response);
        return { ok, error, data };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getReportOrderDS = query =>
  handleFetching(actions.fetchReportOrderDS, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(reportsApi.getReportOrderDS, query);
        const { ok, error, data } = getResponse(response);
        return { ok, error, data };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getReportTradesDS = (id, query) =>
  handleFetching(actions.fetchReportTradesDS, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(reportsApi.getReportTradesDS, id, query);
        const { ok, error, data } = getResponse(response);
        return { ok, error, data };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getTransactionsReport = query =>
  handleFetching(actions.fetchTransactionReport, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(reportsApi.getTransactionsReport, query);
        const { ok, error, data } = getResponse(response);
        return { ok, error, data };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getTransactionsReportStatus = query =>
  handleFetching(actions.fetchTransactionReport, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(reportsApi.getTransactionsReportStatus, query);
        const { ok, error, data } = getResponse(response);
        return { ok, error, data };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getReportBarsDS = query =>
  handleFetching(actions.fetchReportBarsDS, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(reportsApi.getReportBarsDS, query);
        const { ok, error, data } = getResponse(response);
        return { ok, error, data };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });
