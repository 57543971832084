import { initialFetching, createFetching } from 'symbiote-fetching';
import { createSymbiote } from 'redux-symbiote';

export const initialState = {
  fetchOne: initialFetching,
  fetchExchangeRateSourcesForCreate: initialFetching,
  cryptoCurrencies: [],
  currencyPairs: [],
  currencyPairsForCreate: [],
  exchangeRateSources: [],
  exchangeRateSourcesForCreate: [],
  symbols: [],
  total: 0,
};

const symbiote = {
  fetchOne: createFetching('fetchOne'),
  fetchExchangeRateSourcesForCreate: createFetching('fetchExchangeRateSourcesForCreate'),
  setCryptoCurrencies: (state, data) => ({ ...state, cryptoCurrencies: data }),
  setCurrencyPairs: (state, data) => ({ ...state, currencyPairs: data }),
  setCurrencyPairsForCreate: (state, data) => ({ ...state, currencyPairsForCreate: data }),
  setExchangeRateSources: (state, data) => ({ ...state, exchangeRateSources: data }),
  setExchangeRateSourcesForCreate: (state, data) => ({ ...state, exchangeRateSourcesForCreate: data }),
  setTotal: (state, total) => ({ ...state, total }),
};

export const { actions, reducer } = createSymbiote(initialState, symbiote, 'cryptoCurrencies');
