import React from 'react';

import { ClientRoutes, ClientTabsRoutes, ClientTypeForm, ClientTypeShow, ClientsTabsRoutes } from './models';

const ClientsPage = React.lazy(() => import('./pages/clients-page'));
const ActiveClientsTab = React.lazy(() => import('./components/clients/active-clients-tab'));
const DeletedClientsTab = React.lazy(() => import('./components/clients/deleted-clients-tab'));
const RefferalProgramTab = React.lazy(() => import('./referral-program/pages/referral-program-page'));
const NotificationsTab = React.lazy(() => import('../notifications/components/notifications-tab'));
const ClientFormPage = React.lazy(() => import('./pages/client-form-page'));
const InvestorIndividualForm = React.lazy(() => import('./components/client/individual/investor-form'));
const InvestorLegalForm = React.lazy(() => import('./components/client/legal/investor-form'));
const LegalForm = React.lazy(() => import('./components/client/legal/legal-form'));
const OperatorForm = React.lazy(() => import('./components/client/legal/operator-form'));
const Accounts = React.lazy(() => import('./features/accounts/accounts'));
const AccessOfOffering = React.lazy(() => import('./features/access-offering/components/accessOfOfferings'));
const Users = React.lazy(() => import('features/clients/pages/users'));
const PaymentDestinationsTab = React.lazy(() => import('./features/payment-destinations/payment-destinations-tab'));
const ApiKeysTab = React.lazy(() => import('./features/api-keys/api-keys-tab'));
const MarketingProgramsTab = React.lazy(() => import('./features/marketing-programs/marketing-programs-tab'));

const { INVESTOR_INDIVIDUAL, INVESTOR_LEGAL, ISSUER, BROKER, MARKET_OPERATOR } = ClientTypeForm;
const { legalEntity, individual } = ClientTypeShow;

const NEW = 'new';
const MODE = ':mode/:id';

const ClientsChildrenMode = {
  INDIVIDUAL: [
    {
      path: NEW,
      element: <InvestorIndividualForm />,
    },
    {
      path: MODE,
      children: [
        {
          path: ClientTabsRoutes.INFO,
          element: <InvestorIndividualForm />,
        },
        {
          path: ClientTabsRoutes.ACCOUNTS,
          element: <Accounts />,
        },
        {
          path: ClientTabsRoutes.OFFERINGS,
          element: <AccessOfOffering />,
        },
        {
          path: ClientTabsRoutes.USERS,
          element: <Users />,
        },
        {
          path: ClientTabsRoutes.DESTINATIONS,
          element: <PaymentDestinationsTab />,
        },
        {
          path: ClientTabsRoutes.API_KEYS,
          element: <ApiKeysTab />,
        },
        {
          path: ClientTabsRoutes.PROGRAMS,
          element: <MarketingProgramsTab />,
        },
      ],
    },
  ],
  LEGAL: [
    {
      path: NEW,
      element: <InvestorLegalForm />,
    },
    {
      path: MODE,
      children: [
        {
          path: ClientTabsRoutes.INFO,
          element: <InvestorLegalForm />,
        },
        {
          path: ClientTabsRoutes.ACCOUNTS,
          element: <Accounts />,
        },
        {
          path: ClientTabsRoutes.OFFERINGS,
          element: <AccessOfOffering />,
        },
        {
          path: ClientTabsRoutes.USERS,
          element: <Users />,
        },
        {
          path: ClientTabsRoutes.DESTINATIONS,
          element: <PaymentDestinationsTab />,
        },
        {
          path: ClientTabsRoutes.API_KEYS,
          element: <ApiKeysTab />,
        },
        {
          path: ClientTabsRoutes.PROGRAMS,
          element: <MarketingProgramsTab />,
        },
      ],
    },
  ],
  ISSUER: [
    {
      path: NEW,
      element: <LegalForm />,
    },
    {
      path: MODE,
      children: [
        {
          path: ClientTabsRoutes.INFO,
          element: <LegalForm />,
        },
        {
          path: ClientTabsRoutes.ACCOUNTS,
          element: <Accounts />,
        },
        {
          path: ClientTabsRoutes.OFFERINGS,
          element: <AccessOfOffering />,
        },
        {
          path: ClientTabsRoutes.USERS,
          element: <Users />,
        },
        {
          path: ClientTabsRoutes.DESTINATIONS,
          element: <PaymentDestinationsTab />,
        },
        {
          path: ClientTabsRoutes.API_KEYS,
          element: <ApiKeysTab />,
        },
        {
          path: ClientTabsRoutes.PROGRAMS,
          element: <MarketingProgramsTab />,
        },
      ],
    },
  ],
  BROKER: [
    {
      path: NEW,
      element: <LegalForm />,
    },
    {
      path: MODE,
      children: [
        {
          path: ClientTabsRoutes.INFO,
          element: <LegalForm />,
        },
        {
          path: ClientTabsRoutes.ACCOUNTS,
          element: <Accounts />,
        },
        {
          path: ClientTabsRoutes.OFFERINGS,
          element: <AccessOfOffering />,
        },
        {
          path: ClientTabsRoutes.USERS,
          element: <Users />,
        },
        {
          path: ClientTabsRoutes.DESTINATIONS,
          element: <PaymentDestinationsTab />,
        },
        {
          path: ClientTabsRoutes.API_KEYS,
          element: <ApiKeysTab />,
        },
      ],
    },
  ],
  OPERATOR: [
    {
      path: MODE,
      children: [
        {
          path: ClientTabsRoutes.INFO,
          element: <OperatorForm />,
        },
        {
          path: ClientTabsRoutes.ACCOUNTS,
          element: <Accounts />,
        },
        {
          path: ClientTabsRoutes.USERS,
          element: <Users />,
        },
      ],
    },
  ],
};

export const clientsRoutes = [
  {
    path: ClientRoutes.CLIENTS_ROOT,
    element: <ClientsPage />,
    children: [
      {
        path: ClientsTabsRoutes.ACTIVE,
        element: <ActiveClientsTab/>,
      },
      {
        path: ClientsTabsRoutes.DELETED,
        element: <DeletedClientsTab/>,
      },
      {
        path: ClientsTabsRoutes.REFERRAL,
        element: <RefferalProgramTab/>,
      },
      {
        path: ClientsTabsRoutes.NOTIFICATIONS,
        element: <NotificationsTab/>,
      },
    ],
  },
  {
    path: ClientRoutes[INVESTOR_INDIVIDUAL],
    element: <ClientFormPage clientRole={individual} clientType={INVESTOR_INDIVIDUAL} />,
    children: ClientsChildrenMode.INDIVIDUAL,
  },
  {
    path: ClientRoutes[INVESTOR_LEGAL],
    element: <ClientFormPage clientRole={legalEntity} clientType={INVESTOR_LEGAL} />,
    children: ClientsChildrenMode.LEGAL,
  },
  {
    path: ClientRoutes[ISSUER],
    element: <ClientFormPage clientRole={legalEntity} clientType={ISSUER} />,
    children: ClientsChildrenMode.ISSUER,
  },
  {
    path: ClientRoutes[BROKER],
    element: <ClientFormPage clientRole={legalEntity} clientType={BROKER} />,
    children: ClientsChildrenMode.BROKER,
  },
  {
    path: ClientRoutes[MARKET_OPERATOR],
    element: <ClientFormPage clientRole={legalEntity} clientType={MARKET_OPERATOR} />,
    children: ClientsChildrenMode.OPERATOR,
  },
];
